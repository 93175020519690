import HeaderTitle from "@/components/Header/Title";
import { Button, Col, Row } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CommunityScreen = () => {
  const [contents, setContents] = useState([]);
  const getData = () => {
    fetch("/datasets/community.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setContents(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <HeaderTitle
        title={`กิจกรรมชุมชน`}
        image={`/images/community/activity.jpg`}
        position={`30% 80%`}
      />
      <div className="container mx-auto">
        <p className="text-[18px] font-semibold">
          SEKISUI S-LEC THAILAND has many CSR activities. We do activities for
          the organization either for society, laws and regulations.
        </p>
      </div>
      <div className="container mx-auto mb-12 mt-6">
        <Row gutter={[32, 32]}>
          {contents.length > 0 &&
            contents &&
            contents.map((item: any, index: number) => (
              <Col span={8} key={index}>
                <div className="p-4 border-solid border border-[#ddd]">
                  <div className="min-h-[228px] max-h-[228px] overflow-hidden">
                    <img
                      alt={``}
                      src={item?.image}
                      width={`100%`}
                      height={`228px`}
                    />
                  </div>
                  <div className="text-[16px] font-kanit mt-2">
                    {item?.title}
                  </div>
                  <p className="font-kanit text-[#999] text-[16px] truncate">
                    {item?.detail}
                  </p>
                  <div className="text-right block justify-end">
                    <Link to={`/community-csr/thailand-safe-work-33`}>
                      <Button
                        type="primary"
                        size="small"
                        className="px-4 py-1"
                        style={{ boxShadow: "none" }}
                      >
                        อ่านต่อ...
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default CommunityScreen;
