const ROUTE = {
  ROOT: "/",
  AUTH_LOGIN: "/auth/login",
  ACCOUNT: "/account",
  CONTACT_US: "/contact-us",
  COMMUNITY_CSR: "/community-csr",
  COMMUNITY_CSR_DETAIL: "/community-csr/:slug",
  CAREERS: "/careers",
  CAREERS_DETAIL: "/careers/:id",
  HISTORY: "/history",
  MISSION: "/mission",
  INTEGRAL_POLICY: "/integral-policy",
  SETTING: "/setting",
  AUTOMOTIVE: {
    CLEARFILM: "/automotive/clearfilm",
    SOUND_ACOUSTIC_FILM: "/automotive/sound-acoustic-film",
    SOLAR_CONTROL_FILM: "/automotive/solar-control-film",
    SOUND_SOLAR_FILM: "/automotive/sound-and-solarfilm",
    WEDGE_FILM_FILM: "/automotive/wedgefilm",
    FUNCTIONS: "/automotive/functions",
    COLOR_VARIATIONS: "/automotive/color-variations",
    PROPERTIES_DATA: "/automotive/properties-data",
  },
  ARCHITECTURE: {
    CLEARFILM: "/architecture/clearfilm",
    SOUND_ACOUSTIC_FILM: "/architecture/sound-acoustic-film",
    SOLAR_CONTROL_FILM: "/architecture/solar-control-film",
    SOUND_AND_SOLAR_CONTROL_FILM: "/architecture/sound-and-solar-control-film",
    PERFORMANCE: "/architecture/performance",
    COLOR_VARIATIONS: "/architecture/color-variations",
    PRODUCT_USE_EXAMPLES: "/architecture/product-use-examples",
    PROPERTIES_DATA: "/architecture/properties-data",
  },
  EVENT_DETAIL: "/event/:id",
};

export { ROUTE };
