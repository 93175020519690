import { ROUTE } from "@/commom/route";
import Title from "@/components/Typography/Title";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Image } from "antd";

const ArchitectureProductUseExamplesScreen = () => {
  const items = [
    {
      productNo: "#6001",
      exteriorColor: "VERY LIGHT GREENISH BLUE",
      transmittance: "82.00%",
    },
    {
      productNo: "#6002",
      exteriorColor: "VERY LIGHT BLUE",
      transmittance: "78.60%",
    },
    {
      productNo: "#6080",
      exteriorColor: "VERY LIGHT BLUE",
      transmittance: "80.30%",
    },
    {
      productNo: "#7018",
      exteriorColor: "BLUISH VERY DUSKY GRAY",
      transmittance: "18.50%",
    },
    {
      productNo: "#7082",
      exteriorColor: "LIGHT BLUISH ROSE GRAY",
      transmittance: "83.10%",
    },
    {
      productNo: "#8003",
      exteriorColor: "LIGHT GREENISH GRAY (BRONZE)",
      transmittance: "79.70%",
    },
    {
      productNo: "#9002",
      exteriorColor: "DUSKY BROWN",
      transmittance: "33.10%",
    },
    {
      productNo: "#9090",
      exteriorColor: "MEDIUM BROWN",
      transmittance: "28.40%",
    },
    {
      productNo: "#1001",
      exteriorColor: "MILKY WHITE",
      transmittance: "1.00%",
    },
  ];

  return (
    <>
      <div className="container mx-auto py-6 mb-12">
        <Title title={`Product Use Examples`} className="mb-4" />
        <div className="text-[16px] my-1">
          S-LEC films are used in many advanced architectural structures around
          the world that make wide use of glass.
        </div>

        <div className="w-full text-center">
          <Image
            src={`/images/architecture/clearfilm/blue.jpg`}
            width={`675px`}
            preview={false}
          />
        </div>

        <div className="w-full text-center flex justify-center flex-auto text-[#576b7c] my-4">
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/arch_clear_film_ex_1.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Riken (Japan)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/arch_clear_film_ex_2.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Shiodome Pedestrian Deck (Japan)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/arch_clear_film_ex_3.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Nikkei Tokyo Head Office (Japan)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/arch_clear_film_ex_4.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              LakeTown OUTLET (Japan)
            </span>
          </div>
        </div>

        <div className="w-full text-center">
          <Image
            src={`/images/architecture/soundacousticfilm/green.jpg`}
            width={`675px`}
            preview={false}
          />
        </div>

        <div className="w-full text-center flex justify-center flex-auto text-[#576b7c] my-4">
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/italian_restaurant_US.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Italian Restaurant, Las Vegas (USA)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/landesbank_baden_wurttemberg_germany.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Landesbank Baden Wurttemberg (Germany)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/marriott_hotel_US.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Marriott Hotel, Seattle (USA)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/seattle_condominium_US.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Seattle Condominium (USA)
            </span>
          </div>
        </div>

        <div className="w-full text-center">
          <Image
            src={`/images/architecture/soundsolarfilm/title.jpg`}
            width={`675px`}
            preview={false}
          />
        </div>

        <div className="w-full text-center flex justify-center flex-auto text-[#576b7c] my-4">
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/hotel_new_otani_tokyo_japan.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Hotel New Otani Tokyo (Japan)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/jr_kurume_station_japan.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Jr Kurume Station (Japan)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/midland_square_japan.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Midland Square (Japan)
            </span>
          </div>
          <div className="flex-1">
            <Image
              src={`/images/product_use_ex/tokyo_skytree_japan.jpg`}
              preview={false}
            />
            <span className="my-3 text-[18px] block px-3 leading-6">
              Tokyo SkyTree® (Japan)
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchitectureProductUseExamplesScreen;
