import { ROUTE } from "@/commom/route";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SiteMap = () => {
  const [contents, setContents] = useState([]);
  const getData = () => {
    fetch("/datasets/community.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setContents(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
      <div className="container mx-auto">
        <div className="sitemap pt-8 pb-6">
          <div className="flex gap-12">
            <div className="flex-1">
              <div className="title font-supermarket">เกี่ยวกับบริษัท</div>
              <p className="text-[16px]">
                เซกิซุย เอส-เล็ค (ประเทศไทย)
                มุ่งมั่นที่จะนำเสนอผลิตภัณฑ์คุณภาพสูงและบริการที่เหนือกว่าเพื่อจุดประสงค์ในการตอบสนองความคาดหวังของลูกค้า
              </p>
            </div>
            <div className="flex-1">
              <div className="title font-supermarket">กิจกรรมชุมชน</div>
              {contents && (
                <ul>
                  {contents.length > 0 &&
                    contents.slice(0, 4).map((item: any, index: number) => (
                      <li key={index}>
                        <Link to={`${ROUTE.COMMUNITY_CSR}/${item.id}`}>
                          {item?.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div className="flex-1">
              <div className="title font-supermarket">ติดต่อเรา</div>
              <p className="text-[16px]">
                64/31 หมู่ 4, นิคมอุตสาหกรรมอีสเทิร์นซีบอร์ด, <br />
                ตำบลปลวกแดง, อำเภอปลวกแดง, จังหวัดระยอง 21140
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
