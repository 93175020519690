import { Image } from "antd";

const Title = ({ title }: { title: string }) => {
  return (
    <div className="flex gap-2">
      <span
        style={{ width: "18px", height: "18px" }}
        className="bg-blue rounded-full block"
      ></span>
      <span className="font-supermarket text-blue text-[28px]">{title}</span>
    </div>
  );
};

const HistoryScreen = () => {
  const itemsList = [
    {
      title: "09/2000",
      value: "Establishment of Sekisui S-Lec (Thailand) Co.,Ltd.",
    },
    {
      title: "11/2000",
      value: "Starting of plant construction.",
    },
    {
      title: "10/2001",
      value: "Mechanical completion of plant.",
    },
    {
      title: "30/11/2001",
      value: "First S-Lec film produced.",
    },
    {
      title: "12/01~1/2002",
      value: "Trial product for customer evaluation.",
    },
    {
      title: "02/2002",
      value: "Starting commercial production.",
    },
    {
      title: "10/2002",
      value: "ISO9001:2000 (Upgrade to be ISO9001:2015 on 12/2017)",
    },
    {
      title: "12/2005",
      value: "ISO/TS16949:2002 (Upgrade to be IATF16949:2016 on 12/2017)",
    },
    {
      title: "04/2006",
      value: " ISO14001:2004 (Upgrade to be ISO14001:2015 on 06/2018)",
    },
    {
      title: "10/2007",
      value: "OHSAS18001:1999 (Upgrade to be ISO45001 on 06/2019)",
    },
  ];
  return (
    <>
      <div className="container mx-auto mb-12">
        <Image
          src={`images/history/banner_history.jpg`}
          preview={false}
          width={`100%`}
        ></Image>

        <h1 className="title-company font-supermarket text-blue my-[20px] text-[36px] font-normal text-center">
          Company Profile
        </h1>

        <div className="block my-6">
          <Title title={`SST 2000`} />
          <div className="flex my-2 gap-6">
            <div className="w-1/2">
              <Image
                src={`images/history/BGC.jpg`}
                preview={false}
                width={`100%`}
              ></Image>
            </div>
            <div className="w-1/2">
              <ul className="font-kanit text-[16px] m-0 p-0">
                <li className="flex gap-6 border-0 border-b border-solid border-gray py-3">
                  <div className="text-blue font-semibold">Company name :</div>
                  <div className="text-dark">
                    Sekisui S-Lec (Thailand) Co., Ltd.
                  </div>
                </li>
                <li className="flex gap-6 border-0 border-b border-solid border-gray py-3">
                  <div className="text-blue font-semibold">Established :</div>
                  <div className="text-dark">September 2000</div>
                </li>
                <li className="flex gap-6 border-0 border-b border-solid border-gray py-3">
                  <div className="text-blue font-semibold">
                    Production start :
                  </div>
                  <div className="text-dark">February 2002 (Commercial)</div>
                </li>
                <li className="flex gap-6 border-0 border-b border-solid border-gray py-3">
                  <div className="text-blue font-semibold">Shareholder :</div>
                  <div className="text-dark">Sekisui Chemical Co., Ltd.</div>
                </li>
                <li className="flex gap-6 border-0 border-b border-solid border-gray py-3">
                  <div className="text-blue font-semibold">Address :</div>
                  <div className="text-dark">
                    64/31 Moo 4, Pluakdaeng, Pluakdaeng, Rayong 21140 Thailand.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="block my-6">
          <Title title={`15th Anniversary`} />
          <div className="flex my-2 gap-6">
            <div className="w-1/2">
              <Image
                src={`images/history/15TH.jpg`}
                preview={false}
                width={`100%`}
              ></Image>
              <div className="block mt-6">
                <Title title={`SST 2019`} />
                <div className="flex my-2 gap-6">
                  <Image
                    src={`images/history/DSC_0381.jpg`}
                    preview={false}
                    width={`100%`}
                  ></Image>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <ul className="font-kanit text-[16px] m-0 p-0">
                {itemsList &&
                  itemsList.map((item) => (
                    <li className="flex gap-6 border-0 border-b border-solid border-gray py-2">
                      <div className="text-blue font-semibold w-[100px]">
                        {item?.title}
                      </div>
                      <div className="text-dark">{item?.value}</div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="block my-6"></div>
      </div>
    </>
  );
};

export default HistoryScreen;
