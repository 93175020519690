import Title from "@/components/Typography/Title";
import { Image } from "antd";
import { Link } from "react-router-dom";

const WedgeFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/automotive/wedgefilm/blue-w.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
          <div className="w-[204px] text-center p-[20px]">
            <a
              href={`/files/Sekisui_S-LEC_Product_Brochure.pdf`}
              target="_blank"
            >
              <Image
                src={`/images/photo_cover/Wedge-Brochure-AT-e1513935305182.png`}
                width={`104px`}
                preview={false}
              />{" "}
              <div className="my-1 leading-2">
                Download Our <br />
                Product Brochure
              </div>
            </a>
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <div className="flex gap-4 items-start">
          <div className="flex-1">
            <p className="text-[16px] font-normal indent-4">
              Head-Up Display (HUD) presents the driver with real time vehicle
              data projected directly on the windshield, thus eliminating the
              need to shift the driver’s line of vision. S-LEC™ Wedge Film for
              HUD is a leading technology and the latest solution, integrating
              newer technologies and providing a safer and more comfortable
              experience. Sekisui’s interlayer PVB Wedge films are available in
              the following options: Clear, Sound Acoustic, Solar Control, and
              Sound and Solar film.
            </p>
          </div>
          <div className="flex-1 text-center">
            <video height="100%" width="100%" controls>
              <source src={`/images/hud_eng.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>
        <Title title={`Technology`} className="mb-4 mt-8" />
        <p className="text-[18px] font-semibold text-blue-light py-0 my-0">
          A world's first created by combining three technologies
        </p>

        <p className="text-[16px] font-normal my-1">
          Sekisui S-Lec has successfully developed a wedge-shaped interlayer
          film for HUD that controls the appearance of double imaging and
          ghosting, while providing the benefits of sound and solar control.
          Sekisui’s comprehensive technology offerings for multilayer extrusion,
          nano-particle dispersion, solar control features, and sound
          insulation, provides solutions for even the most challenging design
          and performance applications.
        </p>
        <Title title={`Performance`} className="mb-4 mt-8" />
        <p className="text-[18px] font-semibold text-blue-light py-0 my-0">
          Clearly displays information for the driver
        </p>
        <p className="text-[16px] font-normal my-1">
          Standard windshields cause projector images to appear as blurry,
          double images due to the refraction of light. HUD windshields with
          Sekisui S-Lec engineered wedge angle controls the refraction of light,
          enabling real time images to be projected clearly. S-LEC™ Wedge Film
          for HUD enhances visibility and contributes to a more comfortable and
          safer driving experience for the consumer.
        </p>

        <div className="my-4 block">
          <Image
            src={`/images/automotive/wedgefilm/wedgedemo.png`}
            width={`100%`}
            preview={false}
          />
        </div>
        <div className="h-[1px] bg-gray w-full my-4"></div>
        <p className="text-[18px] font-semibold text-blue-light py-0 my-0">
          Shields unnecessary noise and heat for the driver
        </p>
        <p className="text-[16px] font-normal my-1">
          As clearly demonstrated by the graphs, Sekisui has made it possible to
          have a film for HUD that combines a high level of sound insulation and
          solar-heat reducing performance.
        </p>
        <div className="flex my-4">
          <Image
            src={`/images/automotive/wedgefilm/A9RA74F.png`}
            width={`100%`}
            preview={false}
          />
          <Image
            src={`/images/automotive/wedgefilm/A9RA753.png`}
            width={`100%`}
            preview={false}
          />
        </div>

        <div className="h-[1px] bg-gray w-full my-4"></div>
        <p className="text-[18px] font-semibold text-blue-light py-0 my-0">
          For more information about Sound Acoustic and Solar Control
          properties, please visit the pages below.
        </p>
        <div className="flex flex-wrap text-center justify-center">
          <div className="">
            <Image
              src={`/images/automotive/wedgefilm/auto_sound_acoustic_film.jpg`}
              width={`100%`}
              preview={false}
            />
          </div>
          <div className="">
            <Image
              src={`/images/automotive/wedgefilm/auto_solar_control_film.jpg`}
              width={`100%`}
              preview={false}
            />
          </div>
          <div className="">
            <Image
              src={`/images/automotive/wedgefilm/auto_sound_and_solar_film.jpg`}
              width={`100%`}
              preview={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WedgeFilmScreen;
