import Title from "@/components/Typography/Title";
import { Image } from "antd";

const SolarControlFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/architecture/solarcontrolfilm/title.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
        </div>
        <Title title={`Background`} className="mb-4 text-orange" />
        <p className="text-[16px] font-normal indent-4">
          The windows in cars and buildings are becoming increasingly larger in
          size to increase the amount of light and create a desirable feeling of
          openness. However, window size is proportional to the intrusion of
          heat and air conditioner use. Therefore, windows which allow visible
          light to enter but block infrared light, are important to reduce the
          burden on air conditioning systems and save energy. S-LEC™ Solar
          Control Film filters out heat rays (mid-infrared rays) from sunlight
          and is an essential material for use in architecture for the future.
          Less burden is placed on air conditioning systems, ultimately saving
          energy. You can reach the same insulation with thinner glass
          combinations, and utilize easier and more affordable constructions.
        </p>

        <Title title={`Technology`} className="mb-4 mt-8 text-orange" />
        <p className="text-[18px] font-semibold text-orange pb-0 mb-0">
          Fine particles with infrared ray filtering function distributed in
          interlayer film
        </p>
        <div className="flex gap-12">
          <div className="flex-1">
            <Image
              preview={false}
              src={`/images/architecture/solarcontrolfilm/sole_1.jpg`}
            />
          </div>
          <div className="flex-1">
            <p className="text-[16px] font-normal">
              S-LEC Solar Control Film incorporates Sekisui’s fine particle
              dispersion technology which allows a variety of engineering
              functions to be evenly distributed within a PVB interlayer film
              resin. This technology significantly reduces the transmittance of
              UVA, UVB and infrared rays. The reduced UV exposure and heat on
              the skin protects the occupant and enhances their comfort.
            </p>
          </div>
        </div>
        <Title title={`Performance`} className="mb-4 mt-8 text-orange" />
        <p className="text-[18px] font-semibold text-orange">
          Glass shields heat, filters out mid-infrared rays to mitigate heat
          source
        </p>
        <div className="flex gap-4 items-start">
          <div className="flex-1">
            <p className="text-[16px] font-normal">
              While standard PVB interlayer film filters out more than 99% of
              ultraviolet rays, most of the infrared rays penetrate the film.
              S-LEC™ Solar Control Film for architectural use substantially cuts
              ultraviolet rays as well as mid-infrared rays from 1,300 to 2,100
              nm - the primary cause of the burning sensation on the skin.
            </p>
          </div>
          <div className="flex-1 text-center">
            <Image
              preview={false}
              width={`80%`}
              src={`/images/architecture/solarcontrolfilm/arch_solar_control_film2.jpg`}
            />
          </div>
        </div>
        <h2 className="font-normal text-[22px] text-orange">
          Substantially cuts ultraviolet rays harmful to skin and products
        </h2>
        <p className="text-[16px] font-normal">
          S-LEC™ Solar Control Film has superior UV filtering performance to
          standard PVB film. As evident in the graph, the film substantially
          cuts the harmful ultraviolet rays that enter a room. S-LEC™ Solar
          Control Film protects people’s skin from sun damage and prevents
          discoloration of products displayed in shop windows. It is ideal for
          art gallery and museum displays to protect valuable assets.
        </p>
        <div className="text-center flex gap-12 items-center justify-center">
          <Image
            preview={false}
            width={`40%`}
            src={`/images/architecture/solarcontrolfilm/sound_acoustic_film3.jpg`}
          />
          <Image
            preview={false}
            width={`40%`}
            src={`/images/architecture/solarcontrolfilm/sound_acoustic_film4.jpg`}
          />
        </div>
      </div>
    </>
  );
};

export default SolarControlFilmScreen;
