import { Space } from "antd";

const CompanyDetail = () => {
  return (
    <Space direction="vertical" align="center" className="py-4">
      <h1 className="title-company font-supermarket text-blue my-[20px] text-[36px] font-normal">
        ยินดีต้อนรับ เซกิซุย เอส-เล็ค (ประเทศไทย)
      </h1>
      <p className="indent-4 text-center text-[16px] font-kanit">
        Sekisui S-LEC ™ interlayer film is widely used around the world in the
        production of laminated glass for automotive and architectural use, in
        order to offer safety, protection against UV rays, acoustic and thermal
        insulation, among other benefits.
      </p>
      <p className="indent-4 text-center text-[16px] font-kanit">
        With over 70 years of Sekisui S-LEC film and presence in Thailand over
        15 years, SEKISUI S-LEC THAILAND is the undisputed leader of high
        performance PVB interlayer film for automotive industry providing a wide
        range of solutions that help laminated glass processors and automotive
        assemblers to meet the needs not only of the regional market but also of
        the global market.
      </p>
    </Space>
  );
};

export default CompanyDetail;
