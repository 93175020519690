// import { checkLogin } from "@/commom/utils/authenUtil";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ProtectedRoute = ({ children }: Props) => {
  // const { isLogin, role, errorMessage } = checkLogin();
  return <div>{children}</div>;
};

export default ProtectedRoute;
