import { ROUTE } from "@/commom/route";
import HeaderTitle from "@/components/Header/Title";
import { Empty } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CareerScreen = () => {
  const [carees, setCarees] = useState<any>([]);
  const getData = () => {
    fetch("/datasets/carees.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setCarees(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <HeaderTitle
        title={`Carees (ร่วมงานกับเรา)`}
        image={`/images/carees/banner_header.jpg`}
        position={`100% 60%`}
      />
      <div className="container mx-auto py-6">
        <h1 className="title-company font-supermarket text-blue my-[16px] text-[36px] font-normal">
          เชิญมาร่วมงานกับบริษัท เซกิซุย เอส-เล็ค (ประเทศไทย) จำกัด
        </h1>
        <p className="text-[18px] font-normal font-kanit border-0 border-b border-solid border-gray pb-6">
          We are a BOI privileged company manufacturing the PVB interlayer Film
          for Laminated Glass, we are searching for the new energetic & high
          potencial members to join our grownth in the job vacancy as below :
        </p>

        <div className="py-2">
          <h1 className="font-supermarket text-dark text-[26px] font-normal">
            ตำแหน่งที่เปิดรับ
          </h1>

          {carees.length > 0 &&
            carees.map((item: any, index: number) => (
              <Link to={`${ROUTE.CAREERS}/${item.id}`} key={index}>
                <div className="p-3 px-4 bg-[#f5f5f5] text-blue my-2 cursor-pointer hover:bg-blue hover:text-white text-base font-semibold select-none">
                  {item.title}
                </div>
              </Link>
            ))}
          {carees.length <= 0 && (
            <Empty
              description={"ไม่พบตำแหน่งที่เปิดรับ"}
              style={{ textAlign: "center" }}
              imageStyle={{
                width: "64px",
                margin: "auto",
                padding: "0",
              }}
            />
          )}
          <div className="h-[1px] bg-gray w-full my-4"></div>
        </div>
      </div>
    </>
  );
};

export default CareerScreen;
