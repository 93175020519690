import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Suspense, useContext } from "react";
import { CommonContext } from "./context/common/CommonContext";
// import { ROUTE } from "./commom/route";
import "@/styles/globals.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import CustomModal from "./components/Common/CustomModal";
import CookieConsentV1 from "./components/CookieConsent";

function App() {
  const {
    state: { globalLoading },
  } = useContext(CommonContext);
  const loading = <LoadingOutlined style={{ fontSize: 50 }} />;

  return (
    <div>
      <CookieConsentV1 />
      {globalLoading && (
        <div id="global-loading">
          <Spin indicator={loading} />
        </div>
      )}
      <CustomModal />
      <Suspense
        fallback={
          <div id="global-loading">
            <Spin indicator={loading} />
          </div>
        }
      >
        <RouterProvider
          router={router}
          fallbackElement={<Spin indicator={loading} />}
        />
      </Suspense>
    </div>
  );
}

export default App;
