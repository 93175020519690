const showModal = (oldState: any, payload: { modalConfig: any }) => {
  const { modalConfig: newModalConfig } = payload;
  return {
    ...oldState,
    isShowModal: true,
    modalConfig: { ...oldState.modalConfig, ...newModalConfig },
  };
};

export { showModal };
