import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer text-center">
      <Link to={`/`}>PRIVACY NOTICE</Link> |{" "}
      <span>
        © 2018 Sekisui S-LEC Thailand บริษัท เซกิซุย เอส-เล็ค (ประเทศไทย) จำกัด
      </span>
    </div>
  );
};

export default Footer;
