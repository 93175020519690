import { CommonContext } from "@/context/common/CommonContext";
import { Modal } from "antd";
import { useContext } from "react";

const CustomModal = () => {
  const {
    state: { isShowModal, modalConfig },
  } = useContext(CommonContext);

  const { type, callback, title, description, ...otherModalProps } =
    modalConfig || {};

  return (
    <Modal
      title={title}
      open={isShowModal}
      onCancel={() => {
        if (typeof callback === "function") {
          callback();
        }
      }}
      footer={null}
      closeIcon={null}
      destroyOnClose
      {...otherModalProps}
      width={768}
    >
      <div className="custom-modal-content-container">{description}</div>
    </Modal>
  );
};

export default CustomModal;
