import { ROUTE } from "@/commom/route";
import HeaderTitle from "@/components/Header/Title";
import Title from "@/components/Typography/Title";
import { Button, Empty } from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const CareerDetailScreen = () => {
  let { id } = useParams();
  const [jobDetail, setJobDetail] = useState<any>({});
  const getData = () => {
    fetch("/datasets/carees.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const jobs = myJson;
        const find = jobs.find((item: any) => item.id === id);
        setJobDetail(find);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <HeaderTitle
        title={`Carees (ร่วมงานกับเรา)`}
        image={`/images/carees/banner_header.jpg`}
        position={`100% 60%`}
      />
      <div className="container mx-auto py-6">
        <h1 className="title-company font-supermarket text-blue my-[16px] text-[36px] font-normal">
          เชิญมาร่วมงานกับบริษัท เซกิซุย เอส-เล็ค (ประเทศไทย) จำกัด
        </h1>
        <p className="text-[18px] font-normal font-kanit border-0 border-b border-solid border-gray pb-6">
          We are a BOI privileged company manufacturing the PVB interlayer Film
          for Laminated Glass, we are searching for the new energetic & high
          potencial members to join our grownth in the job vacancy as below :
        </p>

        <div className="py-2">
          <Title title={`Position : ${jobDetail?.title}`} />

          <span className="text-[15px] my-4 block">
            {jobDetail?.detail && jobDetail?.detail.responsibilities && (
              <>
                <b>Responsibilities :</b>
                {jobDetail?.detail.responsibilities.length > 0 && (
                  <ul className="py-0 m-2">
                    {jobDetail?.detail.responsibilities.map((text: string) => (
                      <li>{text}</li>
                    ))}
                  </ul>
                )}
              </>
            )}
            {jobDetail?.detail && jobDetail?.detail.qualification && (
              <>
                <b>Qualification :</b>
                {jobDetail?.detail.qualification.length > 0 && (
                  <ul className="py-0 m-2">
                    {jobDetail?.detail.qualification.map((text: string) => (
                      <li>{text}</li>
                    ))}
                  </ul>
                )}
              </>
            )}
            {jobDetail?.detail && jobDetail?.detail.allowance && (
              <>
                <b>Allowance :</b>
                {jobDetail?.detail.allowance.length > 0 && (
                  <ul className="py-0 m-2">
                    {jobDetail?.detail.allowance.map((text: string) => (
                      <li>{text}</li>
                    ))}
                  </ul>
                )}
              </>
            )}
            {/* <div dangerouslySetInnerHTML={{ __html: jobDetail?.detail }} /> */}
          </span>

          <p className="text-[18px] font-kanit font-light border-0 border-b border-solid border-gray pb-6">
            We offer attractive remuneration with other benefits for successful
            candidates. Interested candidates are requested to submit a resume
            with recent photo and expected salary to{" "}
            <Link to={`mailto:potantin@slecth.com`}>potantin@slecth.com</Link>{" "}
            and{" "}
            <Link to={`mailto:issaraporn@slecth.com`}>
              issaraporn@slecth.com
            </Link>
          </p>

          <Link to={ROUTE.CAREERS}>
            <Button
              type="primary"
              size="small"
              className="px-4 py-1"
              style={{ boxShadow: "none" }}
            >
              Return to Jobs
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CareerDetailScreen;
