import { createBrowserRouter } from "react-router-dom";
import { ROUTE } from "./commom/route";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import HomeScreen from "./pages/homeScreen";
import ContactScreen from "./pages/contactScreen";
import CommunityScreen from "./pages/communityScreen";
import CommunityDetailScreen from "./pages/communityDetailScreen";
import CareerScreen from "./pages/careerScreen";
import CareerDetailScreen from "./pages/careerDetailScreen";
import HistoryScreen from "./pages/historyScreen";
import MissionScreen from "./pages/missionScreen";
import IntegralPolicyScreen from "./pages/integralPolicyScreen";
// Automotive
import AutomotiveClearFilmScreen from "./pages/automotive/clearFilmScreen";
import AutomotiveSoundAcousticFilmScreen from "./pages/automotive/soundAcousticFilmScreen";
import AutomotiveSolarControlFilmScreen from "./pages/automotive/solarControlFilmScreen";
import AutomotiveSoundSolarFilmScreen from "./pages/automotive/soundSolarFilmScreen";
import AutomotiveWedgeFilmScreen from "./pages/automotive/wedgeFilmScreen";
import AutomotiveFunctionsScreen from "./pages/automotive/functionsScreen";
import AutomotiveColorVariationsScreen from "./pages/automotive/colorVariationsScreen";
import AutomotivePropertiesDataScreen from "./pages/automotive/propertiesDataScreen";
// Architecture
import ArchitectureClearFilmScreen from "./pages/architecture/clearFilmScreen";
import ArchitectureSoundAcousticFilmScreen from "./pages/architecture/soundAcousticFilmScreen";
import ArchitectureSolarControlFilmScreen from "./pages/architecture/solarControlFilmScreen";
import ArchitectureSoundSolarControlFilmScreen from "./pages/architecture/soundSolarControlFilmScreen";
import ArchitecturePropertiesDataScreen from "./pages/architecture/propertiesDataScreen";
import ArchitectureColorVariationsScreen from "./pages/architecture/colorVariationsScreen";
import ArchitectureProductUseExamplesScreen from "./pages/architecture/productUseExamplesScreen";
import ArchitecturePerformanceScreen from "./pages/architecture/performanceScreen";
// const AccountScreen = lazy(() => import("./pages/accountScreen"));
// const SettingScreen = lazy(() => import("./pages/settingScreen"));
// const LoginScreen = lazy(() => import("./pages/auth/loginScreen"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <HomeScreen />,
      },
      {
        path: ROUTE.CONTACT_US,
        element: <ContactScreen />,
      },
      {
        path: ROUTE.COMMUNITY_CSR,
        element: <CommunityScreen />,
      },
      {
        path: ROUTE.COMMUNITY_CSR_DETAIL,
        element: <CommunityDetailScreen />,
      },
      {
        path: ROUTE.CAREERS,
        element: <CareerScreen />,
      },
      {
        path: ROUTE.CAREERS_DETAIL,
        element: <CareerDetailScreen />,
      },
      {
        path: ROUTE.HISTORY,
        element: <HistoryScreen />,
      },
      {
        path: ROUTE.MISSION,
        element: <MissionScreen />,
      },
      {
        path: ROUTE.INTEGRAL_POLICY,
        element: <IntegralPolicyScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.CLEARFILM,
        element: <AutomotiveClearFilmScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.SOUND_ACOUSTIC_FILM,
        element: <AutomotiveSoundAcousticFilmScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.SOLAR_CONTROL_FILM,
        element: <AutomotiveSolarControlFilmScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.SOUND_SOLAR_FILM,
        element: <AutomotiveSoundSolarFilmScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.WEDGE_FILM_FILM,
        element: <AutomotiveWedgeFilmScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.FUNCTIONS,
        element: <AutomotiveFunctionsScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.COLOR_VARIATIONS,
        element: <AutomotiveColorVariationsScreen />,
      },
      {
        path: ROUTE.AUTOMOTIVE.PROPERTIES_DATA,
        element: <AutomotivePropertiesDataScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.CLEARFILM,
        element: <ArchitectureClearFilmScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.SOUND_ACOUSTIC_FILM,
        element: <ArchitectureSoundAcousticFilmScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.SOLAR_CONTROL_FILM,
        element: <ArchitectureSolarControlFilmScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.SOUND_AND_SOLAR_CONTROL_FILM,
        element: <ArchitectureSoundSolarControlFilmScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.PERFORMANCE,
        element: <ArchitecturePerformanceScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.COLOR_VARIATIONS,
        element: <ArchitectureColorVariationsScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.PRODUCT_USE_EXAMPLES,
        element: <ArchitectureProductUseExamplesScreen />,
      },
      {
        path: ROUTE.ARCHITECTURE.PROPERTIES_DATA,
        element: <ArchitecturePropertiesDataScreen />,
      },
    ],
  },
]);
