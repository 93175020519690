import { Flex } from "antd";

const BannerProduct = () => {
  return (
    <div className="banner-product my-14">
      <Flex>
        <Flex
          vertical
          className="p-[74px] min-h-[320px] justify-center bg-[#F7F7F7] md:w-1/1 w-1/2"
        >
          <div className="title font-supermarket">AUTOMOTIVE</div>
          <p className="font-kanit text-[16px] font-normal">
            We offer innovative solutions for laminated glass used in the
            automotive industry, such as our "wedge" interlayer film that
            improves the use of HUD (Head-Up Display) technology while providing
            acoustic and solar protection.
          </p>
        </Flex>
        <Flex className="image-product-1 relative md:w-1/1 w-1/2">
          <br />
        </Flex>
      </Flex>
      <Flex>
        <Flex className="image-product-2 relative md:w-1/1 w-1/2">
          <br />
        </Flex>
        <Flex
          vertical
          className="p-[74px] min-h-[320px] justify-center bg-[#F7F7F7] md:w-1/1 w-1/2"
        >
          <div className="title font-supermarket">ARCHITECTURE</div>
          <p className="font-kanit text-[16px] font-normal">
            Sekisui S-LEC interlayer films for laminated glass also offer
            numerous benefits for the architectural and interior design market
            that help specialists in this sector meet technical, functional,
            creative and ecological needs.
          </p>
        </Flex>
      </Flex>
    </div>
  );
};

export default BannerProduct;
