import Title from "@/components/Typography/Title";
import { Image } from "antd";

const ClearFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/architecture/clearfilm/blue.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <p className="text-[16px] font-normal indent-4">
          There is no doubt that glass is an important part of architecture. But
          in order to bring light into a building, there are practical, creative
          and ecological factors that must be taken into account. S-LEC™ Clear
          Film technology helps to answer many of the problems faced by
          architects at the design stage of construction. The 3-layer technology
          raises the standards of laminated glass, combining the properties of
          safety, security and UV filtering, that are essential to the function
          and design of today’s structures.
        </p>
        <Title title={`Performance`} className="mb-4 mt-8" />
        <div className="flex gap-4">
          <div className="w-1/3 bg-[#1e388c] text-white p-6">
            <h1 className="text-center font-supermarket my-4 block font-normal">
              SAFETY
            </h1>
            <div className="text-[16px] indent-4 my-4">
              Tempered glass tends to shatter into fragments on impact. In
              laminated glass with S-LEC™ Film, fragments will adhere to the
              film to minimize injuries occupants. Laminated glass with S-LEC™
              Clear Film absorbs impact, prevents penetration, and resists
              shattering.
            </div>
            <Image
              preview={false}
              src={`/images/architecture/clearfilm/item1.jpg`}
              className="border-solid border-1 border-white"
            />
            <div className="text-[16px] indent-4 my-4">
              Laminated glass with S-LEC™ Clear Film is neither shattered nor
              penetrated in laboratory testing.
            </div>
          </div>
          <div className="w-1/3 bg-[#0096da] text-white p-6">
            <h1 className="text-center font-supermarket my-4 block font-normal">
              SECURITY
            </h1>
            <div className="text-[16px] indent-4 my-4">
              Windows are traditionally an easy entry point for burglaries and
              vandalism. Laminated glass with S-Lec Film resists penetration,
              even with heavy or sharp tools like a hammer or glass cutter. The
              strong, yet flexible properties of S-Lec Film deter theft and
              intrusion, as it requires significantly more effort to breach the
              glass.
            </div>
            <Image
              preview={false}
              src={`/images/architecture/clearfilm/item2.jpg`}
              className="border-solid border-1 border-white"
            />
            <div className="text-[16px] indent-4 my-4">
              Perfect for display cases and store windows, glass with S-LEC™
              Clear Film requires more effort to penetrate than tempered glass.
            </div>
          </div>
          <div className="w-1/3 bg-[#6fd0f6] text-white p-6">
            <h1 className="text-center font-supermarket my-4 block font-normal">
              UV FILTERING
            </h1>
            <div className="text-[16px] indent-4 my-4">
              S-LEC™ Clear Film filters out more than 99% of the sun’s harmful
              ultraviolet rays. This contributes to the reduction of skin damage
              to occupants, as well as the discoloration and deterioration of
              products, furnishings, and other surfaces. Display windows with
              S-LEC™ Clear Film protect items on display from damage due to
              extended UV exposure.
            </div>
            <Image
              preview={false}
              src={`/images/architecture/clearfilm/item3.jpg`}
              className="border-solid border-1 border-white"
            />
            <div className="text-[16px] indent-4 my-4">
              Display windows with S-LEC™ laminated glass help maintain the
              value of goods by protecting them from damaging rays.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearFilmScreen;
