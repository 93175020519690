import { Link, Outlet } from "react-router-dom";
import {
  Image,
  Layout as LayoutMain,
  Menu,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Space,
} from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import { CommonContext } from "@/context/common/CommonContext";
import { useCurrentPath } from "@/commom/utils/routerUtil";
import { ACTIONS } from "@/commom/constants";
import { ROUTE } from "@/commom/route";
import Footer from "../Footer";
import SiteMap from "../SiteMap";
const { Header, Content } = LayoutMain;

const Layout = () => {
  const { state, dispatch } = useContext(CommonContext);
  const { pathname } = useCurrentPath();
  const lang = localStorage.getItem("lang");
  const items: MenuProps["items"] = [
    {
      label:
        lang === "th" ? (
          <Link to={`/`}>หน้าแรก</Link>
        ) : (
          <Link to={`/`}>HOME</Link>
        ),
      key: "/",
    },
    {
      label: lang === "th" ? "เกี่ยวกับบริษัท" : "ABOUT",
      key: "about",
      children: [
        { label: <Link to={ROUTE.HISTORY}>History</Link>, key: ROUTE.HISTORY },
        {
          label: <Link to={ROUTE.MISSION}>Mission, vision and values</Link>,
          key: ROUTE.MISSION,
        },
        {
          label: <Link to={ROUTE.INTEGRAL_POLICY}>Integral Policy</Link>,
          key: ROUTE.INTEGRAL_POLICY,
        },
        {
          label: (
            <Link to={`https://www.sekisuichemical.com/`} target="_blank">
              Sekisui Chemical
            </Link>
          ),
          key: "chemical",
        },
      ],
    },
    {
      label: "AUTOMOTIVE",
      key: "automotive",
      children: [
        {
          label: <Link to={ROUTE.AUTOMOTIVE.CLEARFILM}>S-LEC™ Clear Film</Link>,
          key: "automotive.clearfilm",
        },
        {
          label: (
            <Link to={ROUTE.AUTOMOTIVE.SOUND_ACOUSTIC_FILM}>
              S-LEC™ Sound Acoustic Film
            </Link>
          ),
          key: "automotive.sound_acoustic_film",
        },
        {
          label: (
            <Link to={ROUTE.AUTOMOTIVE.SOLAR_CONTROL_FILM}>
              S-LEC™ Solar Control Film
            </Link>
          ),
          key: "automotive.sound_control_film",
        },
        {
          label: (
            <Link to={ROUTE.AUTOMOTIVE.SOUND_SOLAR_FILM}>
              S-LEC™ Sound & Solar Film
            </Link>
          ),
          key: "automotive.sound_solar_film",
        },
        {
          label: (
            <Link to={ROUTE.AUTOMOTIVE.WEDGE_FILM_FILM}>
              S-LEC™ Wedge Film for HUD
            </Link>
          ),
          key: "automotive.wedgefilm",
        },
        {
          label: <Link to={ROUTE.AUTOMOTIVE.FUNCTIONS}>Functions</Link>,
          key: "automotive.functions",
        },
        {
          label: (
            <Link to={ROUTE.AUTOMOTIVE.COLOR_VARIATIONS}>Color Variations</Link>
          ),
          key: "automotive.variations",
        },
        {
          label: (
            <Link to={ROUTE.AUTOMOTIVE.PROPERTIES_DATA}>Properties Data</Link>
          ),
          key: "automotive.properties",
        },
      ],
    },
    {
      label: "ARCHITECTURE",
      key: "architecture",
      children: [
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.CLEARFILM}>S-LEC™ Clear Film</Link>
          ),
          key: "architecture.clearfilm",
        },
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.SOUND_ACOUSTIC_FILM}>
              S-LEC™ Sound Acoustic Film
            </Link>
          ),
          key: "architecture.sound_acoustic_film",
        },
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.SOLAR_CONTROL_FILM}>
              S-LEC™ Solar Control Film
            </Link>
          ),
          key: "architecture.solar_control_film",
        },
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.SOUND_AND_SOLAR_CONTROL_FILM}>
              S-LEC™ Sound & Solar Film
            </Link>
          ),
          key: "architecture.sound_and_solar_control_film",
        },
        {
          label: <Link to={ROUTE.ARCHITECTURE.PERFORMANCE}>Performance</Link>,
          key: "architecture.performance",
        },
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.COLOR_VARIATIONS}>
              Color Variations
            </Link>
          ),
          key: "architecture.variations",
        },
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.PRODUCT_USE_EXAMPLES}>
              Product Use Examples
            </Link>
          ),
          key: "architecture.product_use",
        },
        {
          label: (
            <Link to={ROUTE.ARCHITECTURE.PROPERTIES_DATA}>Properties Data</Link>
          ),
          key: "architecture.properties",
        },
      ],
    },
    {
      label:
        lang === "th" ? (
          <Link to={ROUTE.COMMUNITY_CSR}>กิจกรรมชุมชน</Link>
        ) : (
          <Link to={ROUTE.COMMUNITY_CSR}>CSR</Link>
        ),
      key: ROUTE.COMMUNITY_CSR,
    },
    {
      label:
        lang === "th" ? (
          <Link to={ROUTE.CONTACT_US}>ติดต่อเรา</Link>
        ) : (
          <Link to={ROUTE.CONTACT_US}>CONTACT US</Link>
        ),
      key: ROUTE.CONTACT_US,
    },
    {
      label:
        lang === "th" ? (
          <Link to={ROUTE.CAREERS}>ร่วมงานกับเรา</Link>
        ) : (
          <Link to={ROUTE.CAREERS}>CAREERS</Link>
        ),
      key: ROUTE.CAREERS,
    },
    {
      label:
        lang === "th" ? (
          <Link
            to={`https://www.sekisui-sea.com/th/personal-data-protection-policy/`}
            target="_blank"
          >
            นโยบายส่วนบุคคล
          </Link>
        ) : (
          <Link
            to={`https://www.sekisui-sea.com/th/personal-data-protection-policy/`}
            target="_blank"
          >
            PDPA
          </Link>
        ),
      key: "pdpa",
    },
  ];

  useEffect(() => {
    if (!state.appMenuSelected && pathname) {
      dispatch({ type: ACTIONS.SET_CURRENT_MENU, payload: { key: pathname } });
      // console.log("pathname", pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu: MenuProps["onClick"] = (e) => {
    dispatch({ type: ACTIONS.SET_CURRENT_MENU, payload: { key: e.key } });
    // navigate(e.key);
  };

  const handleChangeLanguage = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      dispatch({ type: ACTIONS.SET_LANGUAGE, payload: value });
      localStorage.setItem("lang", value);
    },
    [dispatch]
  );

  return (
    <LayoutMain style={{ height: "100vh" }}>
      <Header style={{}}>
        <div className="container mx-auto">
          <Space className="flex justify-between">
            <Space size={16}>
              <Image src={`/images/logo.png`} width={170} preview={false} />
              <h1 style={{ fontSize: "16px", fontWeight: "500" }}>
                Sekisui S-LEC Thailand | เซกิซุย เอส-เล็ค (ประเทศไทย)
              </h1>
            </Space>
            <Space>
              <Radio.Group
                options={[
                  { label: "EN", value: "en" },
                  { label: "TH", value: "th" },
                ]}
                value={lang ?? "en"}
                size="small"
                onChange={handleChangeLanguage}
                optionType="button"
              />
            </Space>
          </Space>
        </div>
      </Header>
      <div
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <div className="container mx-auto">
          <Menu
            triggerSubMenuAction={`click`}
            onClick={handleMenu}
            selectedKeys={[state?.appMenuSelected]}
            mode="horizontal"
            items={items}
          />
        </div>
      </div>
      <LayoutMain>
        <Content style={{ minWidth: "1170px" }}>
          <Outlet />
          <SiteMap />
          <Footer />
        </Content>
      </LayoutMain>
    </LayoutMain>
  );
};
export default Layout;
