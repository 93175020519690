import Title from "@/components/Typography/Title";
import { Col, Row } from "antd";

const ItemShort = (props: any) => {
  const { item } = props;
  return (
    <div
      className={`flex flex-col text-center text-white py-4 min-h-[300px] items-center justify-center ${item.bg}`}
    >
      <span className="text-[14px] px-4">{item.title}</span>
      <div className="px-3 py-4">
        <img src={item.image} />
      </div>
      <span className="px-4 text-[14px]">{item.description}</span>
    </div>
  );
};

const ArchitecturePerformanceScreen = () => {
  const items = [
    {
      bg: "bg-[#000]",
      title: "1.SHEET GLASS (5MM)",
      image: "/images/arch_performance/test1_pic1.jpg",
      description: "Very dangerous because of the sharp glass fragments.",
    },
    {
      bg: "bg-[#1e388c]",
      title: "2.TEMPERED GLASS (5MM)",
      image: "/images/arch_performance/test1_pic2.jpg",
      description: "Shattered, and penetrated by the object.",
    },
    {
      bg: "bg-[#0295da]",
      title: "3.WIRE GLASS (608MM)",
      image: "/images/arch_performance/test1_pic3.jpg",
      description: "The wires are cut, and penetrated by the object.",
    },
    {
      bg: "bg-[#000]",
      title: "4.LAMINATED GLASS WITH S-LEC™ FILM",
      image: "/images/arch_performance/test1_pic4.jpg",
      description: "Neither shattered nor penetrated.",
    },
  ];
  const items2 = [
    {
      bg: "bg-[#000]",
      title: "",
      image: "/images/arch_performance/test2_pic1.jpg",
      description: "",
    },
    {
      bg: "bg-[#1e388c]",
      title: "1. SHEET GLASS",
      image: "/images/arch_performance/test2_pic2.jpg",
      description: "Perfectly shattered by the air blast.",
    },
    {
      bg: "bg-[#0295da]",
      title: "2.WIRE GLASS",
      image: "/images/arch_performance/test2_pic3.jpg",
      description: "Partially broke down.",
    },
    {
      bg: "bg-[#000]",
      title: "3.WIRE GLASS",
      image: "/images/arch_performance/test2_pic4.jpg",
      description: "Only cracked.",
    },
  ];
  return (
    <>
      <div className="container mx-auto py-6 mb-12">
        <Title title={`Safety`} className="mb-4" />
        <div className="text-[16px] my-3">
          Laminated glass with S-LEC™ Film absorbs impact, prevents penetration,
          and stops shattering.
        </div>
        <div className="w-full h-[1px] bg-gray my-2 block"></div>
        <div className="text-[16px] my-3 text-blue font-bold">
          Shot Bag Test
        </div>
        <Row gutter={[24, 6]}>
          {items.map((item) => (
            <Col span={6}>
              <ItemShort item={item} />
            </Col>
          ))}
        </Row>

        <div className="w-full h-[1px] bg-gray my-4 block"></div>
        <div className="text-[16px] my-3 text-blue font-bold">
          Air Blast Pressure Destructive Test
        </div>
        <Row gutter={[24, 6]}>
          {items2.map((item) => (
            <Col span={6}>
              <ItemShort item={item} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ArchitecturePerformanceScreen;
