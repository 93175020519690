import { IApp } from "@/models/app";

const defaultValueCommonContext: IApp = {
  appName: "",
  appMenuSelected: "",
  language: "en",
  globalLoading: false,
  modalConfig: {
    centered: true,
  },
};

export { defaultValueCommonContext };
