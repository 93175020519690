import { ROUTE } from "@/commom/route";
import Title from "@/components/Typography/Title";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const PropertiesDataScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12">
        <Title title={`Properties Data`} className="mb-4" />
        <table
          className="table table-color w-full my-4"
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            <tr className="bg-gray font-bold">
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Item
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Unit
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Conditions
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Results
              </td>
              <td className="p-2 border border-b-0 border-[#e5e5e5] border-solid">
                Test Method
              </td>
            </tr>
            <tr className="">
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Specific gravity
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                -
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                23°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                1.07
              </td>
              <td className="p-2 border border-b-0 border-[#e5e5e5] border-solid">
                Weight-in-air/weight-in-water method
              </td>
            </tr>
            <tr className="">
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Refractive index
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                -
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                23°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                1.48
              </td>
              <td className="p-2 border border-b-0 border-[#e5e5e5] border-solid">
                Abbe's refractometer
              </td>
            </tr>
            <tr className="">
              <td
                rowSpan={2}
                className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid"
              >
                Thermal conductivity
              </td>
              <td
                rowSpan={2}
                className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid"
              >
                W * Em<sup>-1</sup> * K<sup>-1</sup>
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                23°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                0.196
              </td>
              <td
                rowSpan={2}
                className="p-2 border border-b-0 border-[#e5e5e5] border-solid"
              >
                Thermal constants analyzer
              </td>
            </tr>
            <tr className="">
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                50°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                0.214
              </td>
            </tr>
            <tr className="">
              <td
                rowSpan={2}
                className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid"
              >
                Specific heat
              </td>
              <td
                rowSpan={2}
                className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid"
              >
                KJ * kg<sup>-1</sup>*K<sup>-1</sup>
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                20°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                1.9
              </td>
              <td
                rowSpan={2}
                className="p-2 border border-b-0 border-[#e5e5e5] border-solid"
              >
                Differential scanning calorimeter (DSC)
              </td>
            </tr>
            <tr className="">
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                100°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                2.3
              </td>
            </tr>
            <tr className="">
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                Bulk modulus
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                K<sup>-1</sup>
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                23°C
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                1.48
              </td>
              <td className="p-2 border border-b-0 border-[#e5e5e5] border-solid">
                Abbe's refractometer
              </td>
            </tr>
            <tr className="">
              <td
                rowSpan={2}
                className="p-2 border border-r-0 border-[#e5e5e5] border-solid"
              >
                Permittivity
              </td>
              <td
                rowSpan={2}
                className="p-2 border border-r-0 border-[#e5e5e5] border-solid"
              >
                -
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                23°C/50Hz
              </td>
              <td className="p-2 border border-b-0 border-r-0 border-[#e5e5e5] border-solid">
                4.2
              </td>
              <td
                rowSpan={2}
                className="p-2 border border-[#e5e5e5] border-solid"
              >
                Dielectric loss automatic analyzer
              </td>
            </tr>
            <tr className="">
              <td className="p-2 border border-b border-r-0 border-[#e5e5e5] border-solid">
                23°C/1MHz
              </td>
              <td className="p-2 border border-b border-r-0 border-[#e5e5e5] border-solid">
                3.1
              </td>
            </tr>
          </tbody>
        </table>
        <div className="text-[16px] my-1">
          * Information is for normal PVB only.
        </div>
        <div className="text-[16px] my-1">
          ** While data shown are measured value, Sekisui does not guarantee
          these values as our products quality.
        </div>
      </div>
    </>
  );
};

export default PropertiesDataScreen;
