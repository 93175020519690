import type { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  hashed: false,
  token: {
    fontFamily: "Noto Sans Thai",
    fontSize: 14,
    fontSizeLG: 18,
    colorPrimary: "#014da3",
    colorTextSecondary: "rgba(161, 194, 250, 0.16)",
    controlHeight: 38,
    controlHeightLG: 46,
    colorBgTextHover: "none",
  },
  components: {
    Layout: {
      bodyBg: "#FFF",
      headerPadding: "0",
      headerBg: "#FFF",
      colorText: "#333132",
      headerHeight: 68,
      siderBg: "#F7F8F9",
    },
    Menu: {
      itemHeight: 48,
      lineHeight: 22,
      itemBg: "#f5f5f5",
      colorBgTextActive: "#014da3",
      horizontalLineHeight: "50px",
      itemPaddingInline: "0 24px",
      itemHoverColor: "#E7314A",
      itemActiveBg: "#fff",
      itemSelectedBg: "#fff",
      itemHoverBg: "none",
    },
    Button: {
      borderRadius: 0,
      boxShadow: "none",
    },
    Breadcrumb: { lastItemColor: "#4779B8" },
    Input: {
      borderRadius: 0,
      borderRadiusLG: 0,
    },
    Form: {
      fontSize: 15,
      labelFontSize: 15,
      labelRequiredMarkColor: "red",
      itemMarginBottom: 0,
      verticalLabelPadding: "6px 0 0 0",
    },
    Tag: {
      fontSize: 15,
      borderRadius: 6,
      fontSizeSM: 14,
      borderRadiusSM: 0,
    },
    Timeline: {
      fontSize: 15,
      fontSizeSM: 15,
    },
    DatePicker: {},
    Modal: {
      titleFontSize: 22,
      motionBase: 0,
    },
    Table: {
      headerBg: "#E8EAEC",
      headerSortActiveBg: "#E8EAEC",
      headerSortHoverBg: "#E8EAEC",
      headerColor: "#000",
      colorBgContainer: "#FFF",
      padding: 10,
    },
    Radio: {},
  },
};

export default theme;
