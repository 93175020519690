import Carousel from "@/components/Carousel";
import BannerAbout from "@/components/Section/BannerAbout";
import BannerProduct from "@/components/Section/BannerProduct";
import CompanyDetail from "@/components/Section/CompanyDetail";
// import { Helmet } from "react-helmet";

const HomeScreen = () => {
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Sekisui S-LEC Thailand | เซกิซุย เอส-เล็ค (ประเทศไทย)</title>
        <meta
          name="description"
          content="Sekisui S-LEC ™ interlayer film is widely used around the world in the
          production of laminated glass for automotive and architectural use, in
          order to offer safety, protection against UV rays, acoustic and
          thermal insulation, among other benefits."
        />
      </Helmet> */}
      <Carousel />
      <div className="container mx-auto">
        <CompanyDetail />
        <BannerProduct />
      </div>
      <BannerAbout />
    </>
  );
};

export default HomeScreen;
