import Title from "@/components/Typography/Title";
import { Image } from "antd";

const SoundAcousticFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/soundacousticfilm-page/green2.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
          <div className="w-[204px] text-center p-[20px]">
            <a
              href={`/files/Sekisui_S-LEC_Product_Brochure.pdf`}
              target="_blank"
            >
              <Image
                src={`/images/brochure-sound-acoustic-film.jpeg`}
                width={`104px`}
                preview={false}
              />{" "}
              <div className="my-1 leading-2">
                Download Our <br />
                Product Brochure
              </div>
            </a>
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <p className="text-[16px] font-normal indent-4">
          The key challenges facing car manufacturers today are reducing engine,
          road and wind noise to enhance cabin comfort. Sekisui dedicates their
          efforts and interlayer PVB technologies to further improve sound
          transmission loss. S-Lec Film for automotive laminated glass
          dramatically reduces the transmission of exterior noises, such as
          traffic, wind, and other ambient disturbances.
        </p>
        <Title title={`Technology`} className="mb-4 mt-8" />
        <p className="text-[16px] font-bold text-[#387f3a]">
          Three-layered technology that collectively absorbs unwanted noise
        </p>
        <div className="flex gap-4 items-start">
          <Image
            preview={false}
            width={`50%`}
            src={`/images/soundacousticfilm-page/acou_1.jpg`}
          />
          <p className="text-[16px] font-normal indent-4">
            S-LEC™ Sound Acoustic Film is constructed from a patented
            three-layer extrusion technology. The outer layers provide
            mechanical strength and enable adhesion to the glass. The inner
            layer absorbs unwanted sound frequencies. With this technology, the
            sound insulation performance is improved, and still maintains the
            functionality of standard interlayer film.
          </p>
        </div>
        <Title title={`Performance`} className="mb-4 mt-8" />

        <p className="text-[16px] font-normal indent-4">
          Unwanted audible sound and noise disturbances experienced within the
          automotive cabin, such as traffic and wind, are located in the 1,000
          to 4,000 Hz range. S-LEC™ Sound Acoustic Film improves sound
          transmission loss of these noises by 10dB compared to standard PVB
          interlayer film. S-Lec Film’s superior dampening performance controls
          low frequency vibration energy from the engine (100 to 500Hz) that
          resonates across the windshield.
        </p>
        <p className="text-[16px] font-bold text-[#387f3a]">
          Acoustic Technologies Outperforming Standard Product in Audible Range
        </p>
        <div className="flex gap-12">
          <Image
            preview={false}
            width={`100%`}
            src={`/images/soundacousticfilm-page/acoustic1.png`}
          />
          <Image
            preview={false}
            width={`100%`}
            src={`/images/soundacousticfilm-page/acoustic2.png`}
          />
        </div>
        <ul className="text-[#576b7c] font-semibold text-[16px] leading-8 p-0">
          <li>
            Differences in sound insulation performance are most pronounced for
            sounds in the range of 1,000 to 4,000Hz to which the human ear is
            most effective.
          </li>
          <li>
            S-LEC™ Sound Acoustic Film attains sound reduction of 10dB compared
            with normal laminated glass.
          </li>
        </ul>
      </div>
    </>
  );
};

export default SoundAcousticFilmScreen;
