import Title from "@/components/Typography/Title";
import { Image } from "antd";

const SoundSolarControlFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/architecture/soundsolarfilm/title.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
        </div>
        <Title title={`Background`} className="mb-4 text-[#a69aca]" />
        <p className="text-[16px] font-normal indent-4">
          In the world today, comfort no longer has to be sacrificed for safety.
          Consumers demand both without concession, and designers are challenged
          to deliver. S-LEC™ Sound and Solar Film has simultaneous sound and
          solar heat shielding abilities, in addition to the intrinsic features
          of safety, security and shielding that you expect from laminated PVB
          glass. This technology enables designers to meet customer expectations
          of form and enhanced function, for a comfortable indoor environment.
        </p>

        <Title title={`Technology`} className="mb-4 mt-8 text-[#a69aca]" />
        <div className="flex gap-12">
          <div className="flex-1">
            <p className="text-[16px] font-normal">
              S-LEC™ Sound and Solar Film is constructed from a patented
              three-layer extrusion technology. The outer layers provide
              mechanical strength and enables adhesion to the glass. The inner
              layer absorbs unwanted sound frequencies. Sekisui’s solar control
              particles are then evenly distributed throughout the multi-layer
              structure. This combination of technologies enables sound
              dampening and heat control in one comprehensive product.
            </p>
          </div>
          <div className="flex-1">
            <Image
              style={{ width: "70%" }}
              preview={false}
              src={`/images/architecture/soundsolarfilm/multilayerextrusion.png`}
            />
          </div>
        </div>
        <Title title={`Performance`} className="mb-4 mt-8 text-[#a69aca]" />
        <p className="text-[18px] font-semibold text-[#a69aca]">
          Glass shields heat, filters out mid-infrared rays to mitigate heat
          source
        </p>
        <div className="flex gap-4 items-start">
          <div className="flex-1 text-center">
            <Image
              preview={false}
              width={`80%`}
              src={`/images/architecture/soundsolarfilm/archssflightmodel.png`}
            />
          </div>
          <div className="flex-1">
            <p className="text-[16px] font-normal">
              While standard PVB interlayer film filters out more than 99% of
              ultraviolet rays, most of the infrared rays penetrate the film.
              S-LEC™ Sound and Solar Film for architectural use substantially
              cuts ultraviolet rays as well as mid-infrared rays from 1,300 to
              2,100 nm - the primary cause of the burning sensation on the skin.
            </p>
          </div>
        </div>
        <div className="h-[1px] bg-gray w-full my-4"></div>
        <h2 className="font-normal text-[22px] text-[#a69aca]">
          Cut sound energy down to a third
        </h2>
        <p className="text-[16px] font-normal">
          As evident in the graph, S-LEC™ Sound and Solar Film effectively
          controls low frequency vibration energy from the engine and reduces
          high-frequency wind noise. These figures demonstrate that audible
          noise is reduced to a third of its original volume.
        </p>
        <div className="text-center">
          <Image
            preview={false}
            width={`80%`}
            src={`/images/architecture/soundsolarfilm/ssfgraphs.png`}
          />
        </div>
      </div>
    </>
  );
};

export default SoundSolarControlFilmScreen;
