type Props = {
  title: String;
  image?: String;
  position?: String;
  renderRightSection?: () => JSX.Element;
};
const HeaderTitle = (props: Props) => {
  const { title, image, position } = props;
  return (
    <div
      style={{
        backgroundImage: `url("${image}")`,
        backgroundSize: "cover",
        backgroundPosition: `${position}`,
      }}
      className="w-full flex"
    >
      <div
        className="container mx-auto flex text-center items-center justify-center"
        style={{ minHeight: "288px" }}
      >
        <h1
          style={{ backgroundColor: "rgb(164, 166, 168,0.7)" }}
          className="px-6 py-2 text-white font-normal"
        >
          {title}
        </h1>
      </div>
    </div>
  );
};
export default HeaderTitle;
