import Title from "@/components/Typography/Title";
import { Col, Row } from "antd";

const FunctionsScreen = () => {
  const items = [
    {
      title: "Safety",
      description:
        "While normal tempered glass tends to shatter on impact, laminated glass with S-Lec Film may break but fragments remain adhered on the interlayer film to minimize injuries. Because the laminated glass doesn't shatter into small pieces, a degree of visibility is maintained.",
    },
    {
      title: "Security",
      description:
        "Car burglaries and theft are on the rise. While tempered glass can be broken in an instant, if a car's side and other windows are made of laminated glass with S-Lec Film, the strong, flexible interlayer film makes glass very difficult to penetrate. Thieves usually give up breaking into the car as it takes more time.",
    },
    {
      title: "UV Filtering",
      description:
        "Laminated glass with S-Lec Film filters out more than 99% of the ultraviolet rays that cause sunburns and are harmful to the skin. The film also prevents the discoloration and deterioration of vehicle interiors.",
    },
    {
      title: "Sound Insulation",
      description:
        "S-Lec Film for automotive laminated glass effectively reduces sound from the car exterior, such as traffic noise and the piercing shrill of wind. Using S-LEC™ Sound Acoustic Film with higher sound insulation properties than other standard interlayer films drastically enhances the quiet and comfort of the car's interior.",
    },
    {
      title: "Solar Control",
      description:
        "S-LEC™ Solar Control Film reduces the transmission of heat (mid-infrared) rays from the sun, effectively controlling the in-car temperature and creating a comfortable environment, while improving fuel economy. Tests have shown that with S-LEC™ Solar Control Film applied to the windshield glass, the surface temperature of the steering wheel, that becomes too hot to touch when parked in the summer, was approximately 10 degrees cooler1. Compact cars in a traffic jam that use air conditioning can expect an increase in fuel economy of approximately 3%2. 1. SEKISUI experiment data 2. From the Society of Automotive Engineers of Japan scientific lecture materials (joint announcement by Asahi Glass & Mitsubishi Motors)",
    },
  ];
  return (
    <>
      <div className="container mx-auto py-6 mb-12">
        <Title title={`Functions`} className="mb-4" />
        <Row gutter={[16, 24]}>
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <Col span={8}>
                <div
                  style={{ backgroundColor: "#f5f5f5" }}
                  className="p-2 px-6"
                >
                  <h2 className="text-center text-[20px] font-semibold">
                    {item?.title}
                  </h2>
                  <p className="line-clamp-4">{item?.description}</p>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default FunctionsScreen;
