import Title from "@/components/Typography/Title";
import { Image } from "antd";
import { Link } from "react-router-dom";

const SoundSolarFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/automotive/soundsolarfilm/lavender.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
          <div className="w-[204px] text-center p-[20px]">
            <a
              href={`/files/Sekisui_S-LEC_Product_Brochure.pdf`}
              target="_blank"
            >
              <Image
                src={`/images/photo_cover/Sound-Solar-Film-AT.png`}
                width={`104px`}
                preview={false}
              />{" "}
              <div className="my-1 leading-2">
                Download Our <br />
                Product Brochure
              </div>
            </a>
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <div className="flex gap-4 items-start">
          <div className="flex-1">
            <p className="text-[16px] font-normal indent-4">
              Environmental friendliness, comfort, and safety are the three most
              sought after expectations of consumers for new vehicles today. The
              evolution of S-LEC™ Sound and Solar Film offers more functionality
              to contribute to all three main performance elements:
              environmental impact, comfort, and safety. S-LEC™ Sound and Solar
              interlayer film, is receiving accolades as a revolutionary
              solution, offering a quiet cabin and improving fuel efficiency,
              all while maintaining the high level of safety and security that
              consumers demand.
            </p>
          </div>
          <div className="flex-1 text-center">
            <Image
              preview={false}
              width={`60%`}
              src={`/images/automotive/soundsolarfilm/ssflightmodel.png`}
            />
          </div>
        </div>

        <Title title={`Technology`} className="mb-4 mt-8" />
        <p className="text-[18px] font-normal text-purple">
          S-LEC™ Sound and Solar Film is made possible by incorporating
          multi-layer and fine particle technologies
        </p>
        <div className="flex gap-4 items-start">
          <div className="flex-1 text-center">
            <Image
              preview={false}
              width={`70%`}
              src={`/images/automotive/soundsolarfilm/multilayerextrusion.png`}
            />
          </div>
          <div className="flex-1">
            <p className="text-[16px] font-normal indent-4">
              S-LEC™ Sound and Solar Film is constructed from a patented
              three-layer extrusion technology. The outer layers provide
              mechanical strength and enable adhesion to the glass. The inner
              layer absorbs unwanted sound frequencies. Sekisui’s solar control
              particles are then evenly distributed throughout the multi-layer
              structure. This combination of technologies enables sound
              dampening and heat control in one comprehensive product.
            </p>
          </div>
        </div>
        <Title title={`Performance`} className="mb-4 mt-8" />
        <p className="text-[18px] font-normal text-purple">
          Cut sound energy down to a third
        </p>
        <p className="text-[16px] font-normal indent-4">
          As evident in the graph, S-LEC™ Sound and Solar Film effectively
          controls low frequency vibration energy from the engine and reduces
          high-frequency wind noise. These figures demonstrate that audible
          noise is reduced to a third of its original volume.
        </p>
        <div className="text-center">
          <Image
            preview={false}
            width={`80%`}
            src={`/images/automotive/soundsolarfilm/ssfgraphs.png`}
          />
        </div>
        <p className="text-[18px] font-normal text-purple">
          Mitigate heat from IR rays while electromagnetic waves pass freely
        </p>

        <p className="text-[16px] font-normal indent-4">
          The sun’s mid-infrared rays-between 1,500 to 2,100 nm can result in
          over exposure or a radiation burn of the skin and increased cabin
          temperature. S-LEC™ Sound and Solar Film blocks much of the rays in
          this spectrum, effectively controlling cabin temperature and creating
          a comfortable environment, while reducing demand on air conditioning
          and improving fuel economy.1 S-LEC™ Sound and Solar Film allows most
          radio frequency bands to pass freely, allowing drivers and passengers
          to remain connected while on the go. This technology also enables the
          use of Electronic Toll Collection (ETC) without any interference.
        </p>
        <div className="flex gap-6 items-start my-6">
          <Image
            preview={false}
            width={`80%`}
            src={`/images/automotive/soundsolarfilm/ssfvisiblelight.png`}
          />
          <Image
            preview={false}
            width={`80%`}
            src={`/images/automotive/soundsolarfilm/ssfsteeringwheel.png`}
          />
        </div>
        <p className="text-[16px] font-normal text-purple">
          1. Tests have shown that laminated windshields with S-LEC™ Solar
          Control Film reduce the surface temperature of the steering wheel by
          approximately 10 degrees Celsius. S- LEC™ Solar Control Film
          incorporated in compact cars have experienced a 3% increase in fuel
          economy while using the air conditioning during a traffic jam.
        </p>
      </div>
    </>
  );
};

export default SoundSolarFilmScreen;
