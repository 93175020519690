import { ACTIONS } from "@/commom/constants/action";
import { IApp } from "../../models/app";
import { showModal } from "./Handler";

const reducer = (state: IApp, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_MENU:
      return { ...state, appMenuSelected: action.payload.key };
    case ACTIONS.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case ACTIONS.SHOW_MODAL:
      return showModal(state, action);
    case ACTIONS.HIDE_MODAL:
      return { ...state, isShowModal: false };
    default:
      break;
  }
  return state;
};

export { reducer };
