import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Image, Spin } from "antd";
import { ROUTE } from "@/commom/route";

const CommunityDetailScreen = () => {
  let { slug } = useParams();
  const [contents, setContents] = useState([]);
  const getData = () => {
    fetch("/datasets/community.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setContents(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const content: any = contents.find((item: any) => item.id === slug);

  return (
    <>
      <Spin spinning={content ? false : true}>
        {content && (
          <>
            <div className="container mx-auto py-4">
              <h1 className="text-[32px] text-[#014da3] font-semibold">
                {content.title}
              </h1>
              <span>{content.created_at}</span>
            </div>
            <div className="container mx-auto mb-12">
              <div>
                <Image
                  src={`${content.image}`}
                  width={`675px`}
                  preview={false}
                />
              </div>
              <div className="py-4 text-[16px]">
                <span>{content.detail}</span>
              </div>
              <div className="h-[1px] bg-gray w-full my-4"></div>
              <Link to={ROUTE.COMMUNITY_CSR}>
                <Button
                  type="primary"
                  size="small"
                  className="px-4 py-1"
                  style={{ boxShadow: "none" }}
                >
                  Return to Community
                </Button>
              </Link>
            </div>
          </>
        )}
      </Spin>
    </>
  );
};

export default CommunityDetailScreen;
