import { Carousel } from "antd";
const contentStyle: React.CSSProperties = {
  height: "498px",
  color: "#fff",
  width: "100%",
  backgroundSize: "cover",
  backgroundPosition: "fixed",
  textAlign: "center",
  margin: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const CarouselProfile = () => {
  return (
    <Carousel>
      <div>
        <div style={contentStyle}>
          <video autoPlay={true} muted>
            <source src={`images/video/S_LEC_SOUND.mp4`} type="video/mp4" />
          </video>
        </div>
      </div>
      <div>
        <div style={contentStyle}>
          <video autoPlay={true} muted>
            <source src={`images/video/S_LEC_SOLAR.mp4`} type="video/mp4" />
          </video>
        </div>
      </div>
      <div>
        <div
          style={{
            ...contentStyle,
            backgroundImage: `url(${`images/banners/1577420957.jpg`})`,
          }}
        >
          <div className="container mx-auto w-1/2">
            <h3 className="text-[32px]">S-LEC™ Film</h3>
            <span className="text-[22px] text-center font-normal font-kanit">
              is the leading brand for laminated glass interlayer film.Our film
              is recognized as having a high level of safety, security and UV
              filtering properties that are important to consumers.
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            ...contentStyle,
            backgroundImage: `url(${`images/banners/1577933736.jpg`})`,
          }}
        >
          <div className="container mx-auto w-1/2">
            <h3 className="text-[32px]">S-LEC™ Film</h3>
            <span className="text-[22px] text-center font-normal font-kanit">
              is the leading brand for laminated glass interlayer film.Our film
              is recognized as having a high level of safety, security and UV
              filtering properties that are important to consumers.
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            ...contentStyle,
            backgroundImage: `url(${`images/banners/1577933798.png`})`,
          }}
        >
          <div className="container mx-auto w-1/2">
            <h3 className="text-[32px]">S-LEC™ Film</h3>
            <span className="text-[22px] text-center font-normal font-kanit">
              is the leading brand for laminated glass interlayer film.Our film
              is recognized as having a high level of safety, security and UV
              filtering properties that are important to consumers.
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            ...contentStyle,
            backgroundImage: `url(${`images/banners/1577934625.jpg`})`,
          }}
        >
          <div className="container mx-auto w-1/2">
            <h3 className="text-[32px]">S-LEC™ Film</h3>
            <span className="text-[22px] text-center font-normal font-kanit">
              is the leading brand for laminated glass interlayer film.Our film
              is recognized as having a high level of safety, security and UV
              filtering properties that are important to consumers.
            </span>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default CarouselProfile;
