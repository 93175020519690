import Title from "@/components/Typography/Title";
import { Image } from "antd";
import { Link } from "react-router-dom";

const SolarControlFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/solarcontrolfilm-page/yellow.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
          <div className="w-[204px] text-center p-[20px]">
            <a
              href={`/files/Sekisui_S-LEC_Product_Brochure.pdf`}
              target="_blank"
            >
              <Image
                src={`/images/photo_cover/Solar-Control-Film-AT.png`}
                width={`104px`}
                preview={false}
              />{" "}
              <div className="my-1 leading-2">
                Download Our <br />
                Product Brochure
              </div>
            </a>
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <p className="text-[16px] font-normal indent-4">
          Automotive manufacturers are tasked with designing fuel efficient and
          environmentally friendly vehicle choices to reduce carbon emissions.
          These challenges must be accomplished without sacrificing the
          performance and comfort that consumers demand. As daylight opening
          requests from stylists increase, it yields more acute windshield
          installation angles and increased windshield and sunroof surface
          areas, creating additional challenges in mitigating heat. S-LEC™ Solar
          Control Films reduce the transmission of heat (mid-infrared) rays from
          the sun, effectively controlling the in-car temperature and creating a
          comfortable environment, while reducing demand on air conditioning and
          improving fuel economy.*
        </p>
        <p className="text-[16px] font-normal indent-4 text-red">
          * Tests have shown that compact cars with S-LEC™ Solar Control Film
          that use air conditioning in a traffic jam can expect an increase in
          fuel economy of approximately 3%.
        </p>
        <Title title={`Technology`} className="mb-4 mt-8" />
        <p className="text-[18px] font-semibold text-orange pb-0 mb-0">
          Infrared ray filtering particles are distributed throughout interlayer
          film
        </p>
        <div className="flex gap-12">
          <div className="flex-1">
            <p className="text-[16px] font-normal">
              S-LEC™ Solar Control film incorporates Sekisui’s fine particle
              dispersion technology which allows a variety of engineering
              functions to be evenly distributed within a PVB interlayer film
              resin. This technology significantly reduces the transmittance of
              UVA, UVB and infrared rays. The reduced exposure and heat on the
              skin, enhances the consumer satisfaction and driving experience.
            </p>
          </div>
          <div className="flex-1">
            <Image
              preview={false}
              src={`/images/solarcontrolfilm-page/solar-diagram.png`}
            />
          </div>
        </div>
        <Title title={`Performance`} className="mb-4 mt-8" />
        <p className="text-[18px] font-semibold text-orange">
          With solar control film, heat mitigation performance improves
        </p>
        <div className="flex gap-4 items-start">
          <div className="flex-1 text-center">
            <Image
              preview={false}
              width={`80%`}
              src={`/images/solarcontrolfilm-page/solarcontrolgraph1.jpg`}
            />
          </div>
          <div className="flex-1">
            <p className="text-[16px] font-normal">
              S-LEC™ Solar Control Film quenches many of the sun’s mid-infrared
              rays between 1,500 and 2,100 nm. As demonstrated in the graph, the
              effectiveness of S-LEC™ Solar Control Film surpasses heat
              absorbing green glass.
            </p>
          </div>
        </div>
        <div className="h-[1px] bg-gray w-full my-4"></div>
        <h2 className="font-normal text-[22px]">
          Solar control film mitigates heat without affecting the transmission
          of radio frequencies
        </h2>
        <p className="text-[16px] font-normal">
          S-LEC™ Solar Control Film allows most radio frequency bands to pass
          freely, unlike traditional heat-reflecting glass with a metallic
          coating. S-Lec's Solar Control Reflecting and Absorbing Films allow
          drivers and passengers to remain connected while on the go. These
          technologies also enable the convenient use of Electronic Toll
          Collection (ETC) devices without any interference.
        </p>
        <div className="text-center">
          <Image
            preview={false}
            width={`80%`}
            src={`/images/solarcontrolfilm-page/solar_control_film21.jpg`}
          />
        </div>
      </div>
    </>
  );
};

export default SolarControlFilmScreen;
