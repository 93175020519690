import Detail from "@/components/Typography/Detail";
import Title from "@/components/Typography/Title";
import { Image } from "antd";

const IntegralPolicyScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6">
        <Title title={`Corporate Philosophy`} className="mb-2" />
        <Detail
          desc={`Sekisui Chemical Group will implement our Corporate Philosophy “3S
          principles” and Group Vision to directly deal with global social
          problems and, in turn, help create a sustainable society`}
        />
        <div className="my-6">
          <div className="h-[1px] w-full bg-[#999]"></div>
        </div>
        <Title title={`หลัก 3S`} className="mb-2" />
        <div className="text-center">
          <Image src="images/3S_Thai.jpeg" width={`80%`} preview={false} />
        </div>
      </div>
    </>
  );
};

export default IntegralPolicyScreen;
