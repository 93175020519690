import Title from "@/components/Typography/Title";
import { Image } from "antd";
import { Link } from "react-router-dom";

const ClearFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/clearfilm-page/blue2.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
          <div className="w-[204px] text-center p-[20px]">
            <a
              href={`/files/Sekisui_S-LEC_Product_Brochure.pdf`}
              target="_blank"
            >
              <Image
                src={`/images/Brochure-Email-1.png`}
                width={`104px`}
                preview={false}
              />{" "}
              <div className="my-1 leading-2">
                Download Our <br />
                Product Brochure
              </div>
            </a>
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <p className="text-[16px] font-normal indent-4">
          S-LEC™ Clear Film is the leading brand for laminated glass interlayer
          films. Our film is recognized as having a high level of safety,
          security, and UV filtering properties that are important to consumers.
          S-LEC™ Clear Film is available in normal and Wedge for HUD.
        </p>
        <Title title={`Performance`} className="mb-4 mt-8" />
        <div className="flex gap-4">
          <div className="w-1/3 bg-[#1e388c] text-white p-6">
            <h1 className="text-center font-supermarket my-4 block font-normal">
              SAFETY
            </h1>
            <Image
              preview={false}
              src={`/images/architecture/clearfilm/item1.jpg`}
              className="border-solid border-1 border-white"
            />
            <div className="text-[16px] indent-4 my-4">
              Tempered glass tends to shatter into fragments on impact. In
              laminated glass with S-LEC™ Film, fragments will adhere to the
              film to minimize injuries to passengers. Because the laminated
              glass doesn't shatter into small pieces, a degree of visibility is
              maintained for driver safety.
            </div>
          </div>
          <div className="w-1/3 bg-[#0096da] text-white p-6">
            <h1 className="text-center font-supermarket my-4 block font-normal">
              SECURITY
            </h1>
            <Image
              preview={false}
              src={`/images/architecture/clearfilm/item2.jpg`}
              className="border-solid border-1 border-white"
            />
            <div className="text-[16px] indent-4 my-4">
              Windows are traditionally an easy entry point for burglaries and
              vandalism. While tempered glass can be broken quite easily to gain
              access, laminated glass windows with S-Lec interlayer technology
              requires more effort to penetrate.
            </div>
          </div>
          <div className="w-1/3 bg-[#6fd0f6] text-white p-6">
            <h1 className="text-center font-supermarket my-4 block font-normal">
              UV FILTERING
            </h1>
            <Image
              preview={false}
              src={`/images/architecture/clearfilm/item3.jpg`}
              className="border-solid border-1 border-white"
            />
            <div className="text-[16px] indent-4 my-4">
              S-LEC™ Clear Film filters out more than 97% of the sun’s harmful
              ultraviolet rays. This contributes to the reduction of skin damage
              to passengers, as well as the discoloration and deterioration of
              the automobile interior.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearFilmScreen;
