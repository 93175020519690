import { ROUTE } from "@/commom/route";
import Title from "@/components/Typography/Title";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const ArchitectureColorVariationsScreen = () => {
  const items = [
    {
      productNo: "#6001",
      exteriorColor: "VERY LIGHT GREENISH BLUE",
      transmittance: "82.00%",
    },
    {
      productNo: "#6002",
      exteriorColor: "VERY LIGHT BLUE",
      transmittance: "78.60%",
    },
    {
      productNo: "#6080",
      exteriorColor: "VERY LIGHT BLUE",
      transmittance: "80.30%",
    },
    {
      productNo: "#7018",
      exteriorColor: "BLUISH VERY DUSKY GRAY",
      transmittance: "18.50%",
    },
    {
      productNo: "#7082",
      exteriorColor: "LIGHT BLUISH ROSE GRAY",
      transmittance: "83.10%",
    },
    {
      productNo: "#8003",
      exteriorColor: "LIGHT GREENISH GRAY (BRONZE)",
      transmittance: "79.70%",
    },
    {
      productNo: "#9002",
      exteriorColor: "DUSKY BROWN",
      transmittance: "33.10%",
    },
    {
      productNo: "#9090",
      exteriorColor: "MEDIUM BROWN",
      transmittance: "28.40%",
    },
    {
      productNo: "#1001",
      exteriorColor: "MILKY WHITE",
      transmittance: "1.00%",
    },
  ];

  return (
    <>
      <div className="container mx-auto py-6 mb-12">
        <Title title={`Color Variations`} className="mb-4" />
        <table
          className="table table-color w-full my-4"
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            <tr>
              <td
                className="text-center main border-0 border-t border-b border-[#e5e5e5] border-solid py-2 bg-gray font-bold"
                rowSpan={2}
                width="30%"
              >
                Transparent
              </td>
              <td className="text-center border-0 border-b border-t border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#ccf4ee",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #6001
                </div>
              </td>
              <td className="text-center border-0 border-b border-t border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#BBDFED",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #6002
                </div>
              </td>
              <td className="text-center border-0 border-b border-t border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#e4eef8",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #6002
                </div>
              </td>
              <td className="text-center border-0 border-b border-t border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#f2eeef",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #6002
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#f3ebdf",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #8003
                </div>
              </td>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#bba583",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #9002
                </div>
              </td>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#838788",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #9090
                </div>
              </td>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid p-2">
                <div
                  style={{
                    backgroundColor: "#f2eeef",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #7018
                </div>
              </td>
            </tr>
            <tr>
              <td
                className="text-center main border-0 border-b border-[#e5e5e5] border-solid py-2 bg-gray font-bold"
                width="30%"
              >
                Semitransparent color
              </td>
              <td className="text-center p-2">
                <div
                  style={{
                    backgroundColor: "#fbfbf0",
                    height: "100px",
                    width: "100px",
                    verticalAlign: "middle",
                    paddingTop: "40px",
                  }}
                >
                  #1001
                </div>
              </td>
              <td className="text-center"></td>
              <td className="text-center"></td>
              <td className="text-center"></td>
            </tr>
          </tbody>
        </table>

        <div className="text-[16px] my-1">Color chart for reference only</div>

        <table
          className="table table-color w-full my-4"
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            <tr>
              <td
                className="text-center main border-0 border-t border-b border-r border-l border-[#e5e5e5] border-solid py-2 bg-gray font-bold"
                width="30%"
              >
                Product No
              </td>
              <td
                className="text-center main border-0 border-t border-b border-r border-[#e5e5e5] border-solid py-2 bg-gray font-bold"
                width="30%"
              >
                Exterior Color
              </td>
              <td className="text-center main border-0 border-t border-b border-r border-[#e5e5e5] border-solid py-2 bg-gray font-bold">
                Thickness
              </td>
              <td
                className="text-center main border-0 border-t border-b border-r border-[#e5e5e5] border-solid py-2 bg-gray font-bold"
                width="20%"
              >
                Visible Light
                <br />
                Transmittance
              </td>
            </tr>
            {items &&
              items.map((item, index) => {
                return (
                  <tr>
                    <td className="text-center border-0 border-b border-r border-l border-[#e5e5e5] border-solid py-2">
                      {item?.productNo}
                    </td>
                    <td className="text-center border-0 border-b border-r border-[#e5e5e5] border-solid py-2">
                      {item?.exteriorColor}
                    </td>
                    {index === 0 && (
                      <td
                        className="text-center border-0 border-b border-r border-[#e5e5e5] border-solid py-2"
                        rowSpan={items.length}
                      >
                        0.76mm
                      </td>
                    )}

                    <td className="text-center border-0 border-b border-r border-[#e5e5e5] border-solid py-2">
                      {item?.transmittance}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="text-[16px] my-1">1.Glass used: FL2.5mm / FL2.5m</div>
        <div className="text-[16px] my-1">
          2.Visible Light Transmittance is measured with illuminant A in
          compliance with ISO9050, JIS-R3106
        </div>

        <Title title={`Size and Thickness`} className="mb-4 my-4 mt-8" />
        <table
          className="table table-color w-full my-4"
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            <tr>
              <td
                className="text-center bg-gray border-0 border-t border-b border-[#e5e5e5] border-solid py-2"
                width="40%"
              >
                Thickness
              </td>
              <td
                className="text-center border-0 border-t border-b border-[#e5e5e5] border-solid py-2"
                colSpan={3}
              >
                0.76mm (30mil)
              </td>
            </tr>
            <tr>
              <td
                className="text-center bg-gray border-0 border-b border-[#e5e5e5] border-solid py-2"
                width="40%"
              >
                Width
              </td>
              <td
                className="text-center border-0 border-b border-[#e5e5e5] border-solid py-2"
                colSpan={3}
              >
                Please contact us with your project needs.
              </td>
            </tr>
            <tr>
              <td
                className="text-center bg-gray border-0 border-b border-[#e5e5e5] border-solid py-2"
                width="40%"
              >
                Roll Length *
              </td>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid py-2">
                115m
              </td>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid py-2">
                250m
              </td>
              <td className="text-center border-0 border-b border-[#e5e5e5] border-solid py-2">
                510m
              </td>
            </tr>
          </tbody>
        </table>

        <div className="text-[16px] my-1">
          * The limitation of a total length of a roll depends on the film's
          width and product type.
        </div>
        <div className="text-[16px] my-1">
          * Color films are made-to-order, and bulk orders may be required.
        </div>

        <div className="text-[16px] my-1">
          To learn more about how S-Lec interlayer products can meet your needs,
          please{` `}
          <Link to={ROUTE.CONTACT_US}>contact us.</Link>
        </div>
      </div>
    </>
  );
};

export default ArchitectureColorVariationsScreen;
