import Title from "@/components/Typography/Title";
import { Button, Checkbox, Flex, Form, Image, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";
import ReCAPTCHA, { ReCAPTCHA as ReCAPTCHA2 } from "react-google-recaptcha";
import { useEffect, useRef, useState } from "react";

const AnyReactComponent = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          display: "inline-block",
          padding: "14px",
          borderRadius: "6px",
        }}
      >
        <b style={{ fontSize: 15, paddingBottom: 8 }}>
          Sekisui S-Lec(Thailand) Co.,LTD.{" "}
        </b>
        <div style={{ fontSize: 12 }}>
          64/31 หมู่ 4 นิคมอุตสาหกรรมอีสเทิร์นซีบอร์ด ปลวกแดง Rayong 21140
        </div>
        <div style={{ fontSize: 12, paddingBottom: 8 }}>Thailand</div>
        <a href="https://goo.gl/maps/yRFFVgyXSpDcZFsV9" target="_blank">
          Google Map
        </a>
      </div>
    </>
  );
};

const IconGreen = () => {
  return (
    <div
      style={{
        backgroundColor: "#9ccc65",
        width: "10px",
        height: "10px",
        display: "inline-block",
      }}
    ></div>
  );
};
const IconBlue = () => {
  return (
    <div
      style={{
        backgroundColor: "#014DA3",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        display: "inline-block",
      }}
    ></div>
  );
};

const ContactScreen = () => {
  const captchaRef = useRef(null);
  const [branchs, setContacts] = useState<any>([]);
  const getData = () => {
    fetch("/datasets/contact-us.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setContacts(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const defaultProps = {
    center: {
      lat: 13.0105528,
      lng: 101.1919538,
    },
    zoom: 16,
  };

  const onSubmit = (values: any) => {
    console.log("values", values);
  };

  return (
    <>
      <div style={{ height: "400px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBVAcH4bXAeFcyfjis2CCE1R9YF3cEXa0M" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent />
        </GoogleMapReact>
      </div>
      <div className="container mx-auto py-6">
        <Title title={`Contact us`} className="mb-4" />
        <p className="text-[16px] font-normal indent-4">
          For more information and assistance, please use the address
          information underneath or fill out the contact form to send us a
          message. We will send you a reply swiftly.
        </p>
        <div className="h-[1px] bg-gray w-full my-4"></div>
        <Title title={`Purchasing Policy`} className="mb-4" />
        <Link
          to={`/files/purchasing_policy.pdf`}
          target="_blank"
          style={{
            border: "1px solid #014DA3",
            display: "inline-block",
            padding: "8px 14px",
            marginBottom: "12px",
          }}
        >
          <Image src={`/images/pdf.png`} width={`40px`} preview={false} />
          <span style={{ padding: "0px 6px" }}>Purchasing Policy.pdf</span>
        </Link>
        <div className="h-[1px] bg-gray w-full my-4"></div>
        <Title title={`How can we help ?`} className="mb-4" />
        <Form layout="vertical" colon={true} onFinish={onSubmit}>
          <Flex
            gap="large"
            vertical={false}
            align="space-between"
            className="my-2"
          >
            <Form.Item
              label={`Full Name :`}
              name={`fullName`}
              style={{ width: "100%" }}
              required
            >
              <Input placeholder="Enter Full Name." required />
            </Form.Item>
            <Form.Item
              label={`Email :`}
              name={`email`}
              style={{ width: "100%" }}
              required
            >
              <Input type="email" placeholder="Enter Email." required />
            </Form.Item>
          </Flex>
          <Flex
            gap="large"
            vertical={false}
            align="space-between"
            className="my-2"
          >
            <Form.Item
              label={`Phone :`}
              name={`phone`}
              style={{ width: "100%" }}
            >
              <Input placeholder="Enter Phone." />
            </Form.Item>
            <Form.Item
              label={`Organisation :`}
              name={`organisation`}
              style={{ width: "100%" }}
            >
              <Input placeholder="Enter Organisation." />
            </Form.Item>
          </Flex>
          <Flex
            gap="large"
            vertical={false}
            align="space-between"
            className="my-2"
          >
            <Form.Item
              label={`Message :`}
              name={`message`}
              style={{ width: "100%" }}
              required
            >
              <TextArea rows={4} required />
            </Form.Item>
          </Flex>
          <Flex
            gap="large"
            vertical={false}
            align="space-between"
            className="my-2"
          >
            <Form.Item
              label={`Privacy Policy :`}
              style={{ width: "100%" }}
              name={`privacyPolicy`}
              required
            >
              <Checkbox required>
                I have taken note of the Privacy Policy
              </Checkbox>
            </Form.Item>
            <Form.Item style={{ width: "100%" }}>
              <ReCAPTCHA
                sitekey={`6LdX9o0pAAAAAJfHHawsu-OiIce1YyT6xAcpJXez`}
                ref={captchaRef}
                size="invisible"
                grecaptcha={{}}
              />
            </Form.Item>
          </Flex>

          <Flex
            gap="large"
            vertical={false}
            align="space-between"
            className="my-2"
          >
            <Form.Item>
              <Button type="primary" htmlType="submit">
                SEND MESSAGE
              </Button>
            </Form.Item>
          </Flex>
        </Form>

        <div className="h-[1px] bg-gray w-full my-4"></div>

        <div className="text-right">
          <span className="mx-1">
            <IconGreen /> Plant
          </span>
          <span className="mx-1">
            <IconBlue /> Customer Represetative
          </span>
        </div>
        <div>
          {branchs &&
            branchs.map((item: any, index: number) => {
              return (
                <div className="my-4" key={index}>
                  <Flex
                    style={{
                      width: "100%",
                      backgroundColor: "#EEEEE0",
                      padding: "8px",
                    }}
                    align="center"
                    justify="center"
                    className="border border-solid border-[#ddd]"
                  >
                    <div>
                      <Flex gap={6} align="center" justify="center">
                        <b>{item.title}</b>
                        <Flex gap={4}>
                          {item.icons.map((item: string, index: number) => {
                            return (
                              <div key={index}>
                                {item === "green" && <IconGreen />}
                                {item === "blue" && <IconBlue />}
                              </div>
                            );
                          })}
                        </Flex>
                      </Flex>
                    </div>
                  </Flex>
                  {item.details && (
                    <>
                      {item.details.address && (
                        <Flex className="text-[14px] border border-solid border-[#ddd] border-t-0">
                          <div className="text-center border-r border-t-0 border-l-0 border-b-0 border-solid border-[#ddd] w-[100px] py-[6px]">
                            Address
                          </div>
                          <div className="py-[6px] px-2">
                            {item.details.address}
                          </div>
                        </Flex>
                      )}
                      {item.details.phone && (
                        <Flex className="text-[14px] border border-solid border-[#ddd] border-t-0">
                          <div className="text-center border-r border-t-0 border-l-0 border-b-0 border-solid border-[#ddd] w-[100px] py-[6px]">
                            Phone
                          </div>
                          <div className="py-[6px] px-2">
                            {item.details.phone}
                          </div>
                        </Flex>
                      )}
                      {item.details.fax && (
                        <Flex className="text-[14px] border border-solid border-[#ddd] border-t-0">
                          <div className="text-center border-r border-t-0 border-l-0 border-b-0 border-solid border-[#ddd] w-[100px] py-[6px]">
                            Fax
                          </div>
                          <div className="py-[6px] px-2">
                            {item.details.fax}
                          </div>
                        </Flex>
                      )}
                      {item.details.url && (
                        <Flex className="text-[14px] border border-solid border-[#ddd] border-t-0">
                          <div className="text-center border-r border-t-0 border-l-0 border-b-0 border-solid border-[#ddd] w-[100px] py-[6px]">
                            URL
                          </div>
                          <div className="py-[6px] px-2">
                            <Link to={`${item.details.url}`} target="_blank">
                              {item.details.url}
                            </Link>
                          </div>
                        </Flex>
                      )}
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ContactScreen;
