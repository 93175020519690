import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./theme/themeConfig";
import { AppProvider } from "./context/common/CommonContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <StrictMode>
  <AppProvider>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </AppProvider>
  // </StrictMode>
);
