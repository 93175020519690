import Title from "@/components/Typography/Title";
import { Image } from "antd";

const SoundAcousticFilmScreen = () => {
  return (
    <>
      <div className="container mx-auto py-6 mb-12 font-kanit">
        <div className="flex items-center">
          <div className="w-full text-center">
            <Image
              src={`/images/architecture/soundacousticfilm/green.jpg`}
              width={`675px`}
              preview={false}
            />
          </div>
        </div>
        <Title title={`Background`} className="mb-4" />
        <p className="text-[16px] font-normal indent-4">
          Due to an increasing world population and growing infrastructures,
          more and more architects find themselves working on plans for
          buildings next to busy roads, near airports, or in the cities with
          high levels of traffic. This means extra thought must go into building
          design to adhere to regulations on noise levels and maintain the
          quality of peoples' lives. Sekisui has successfully developed S-LEC™
          Sound Acoustic Film that dramatically increases sound insulation
          performance, protecting people from the noise of the outside world for
          higher levels of comfort. This film has been well received for
          architectural use in housing, offices, schools, and commercial
          facilities.
        </p>
        <Title title={`Technology`} className="mb-4 mt-8" />
        <p className="text-[16px] font-bold text-[#387f3a]">
          Three layered technology that collectively absorbs unwanted noise
        </p>
        <div className="flex gap-12 items-start">
          <Image
            preview={false}
            width={`80%`}
            src={`/images/architecture/soundacousticfilm/sound_acoustic_film1.jpg`}
          />
          <p className="text-[16px] font-normal indent-4">
            S-LEC™ Sound Acoustic Film is constructed from a patented
            three-layer extrusion technology. The outer layers provide
            mechanical strength and enable adhesion to the glass. The inner
            layer absorbs unwanted sound frequencies. With this technology, the
            sound insulation performance is improved, and still maintains the
            functionality of standard interlayer film.
          </p>
        </div>
        <Title title={`Performance`} className="mb-4 mt-8" />

        <p className="text-[16px] font-normal indent-4">
          S-LEC™ Sound Acoustic Film, with its special resin core, delivers
          significantly better sound attenuation than standard PVB film.
          Unwanted audible sound and noise disturbances are located in the 1,000
          to 4,000 Hz range. The decrease in noise is greatest in the frequency
          range where windows easily resonate: around 2000 Hz. S-Lec™ Sound
          Acoustic Film improves sound transmission loss of these noises by 10dB
          compared to standard PVB interlayer film.
        </p>
        <p className="text-[16px] font-bold text-[#576b7c]">
          S-Lec™ Sound Acoustic Film used in creative applications:
        </p>

        <ul className="text-[#576b7c] font-semibold text-[16px] leading-8">
          <li>
            In the glass roof of a football stadium to limit the noise from
            raindrops.
          </li>
          <li>
            In a glass wall by the highway, instead of a traditional concrete
            wall to block out noise.
          </li>
          <li>
            In contemporary buildings to create quieter, ambient environments.
          </li>
        </ul>
        <p className="text-[16px] font-bold text-[#576b7c]">
          Acoustic Technologies Outperforming Standard Product in Audible Range
        </p>
        <div className="flex gap-12 px-24">
          <Image
            preview={false}
            width={`100%`}
            src={`/images/soundacousticfilm-page/acoustic1.png`}
          />
          <Image
            preview={false}
            width={`100%`}
            src={`/images/soundacousticfilm-page/acoustic2.png`}
          />
        </div>
        <ul className="text-[#576b7c] font-semibold text-[16px] leading-8">
          <li>
            Differences in sound insulation performance are most pronounced for
            sounds in the range of 1,000 to 4,000Hz to which the human ear is
            most effective.
          </li>
          <li>
            S-LEC™ Sound Acoustic Film attains sound reduction of 10dB compared
            with normal laminated glass.
          </li>
        </ul>
      </div>
    </>
  );
};

export default SoundAcousticFilmScreen;
