import { createContext, useReducer } from "react";
import { reducer } from "./Reducer";
import { CommonContextProps, IApp } from "@/models/app";
import { defaultValueCommonContext } from "@/commom/constants/context";

export const CommonContext = createContext<{
  state: IApp;
  dispatch: React.Dispatch<CommonContextProps>;
}>({
  state: defaultValueCommonContext,
  dispatch: () => null,
});

type CommonContextProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: CommonContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, defaultValueCommonContext);
  return (
    <CommonContext.Provider value={{ state, dispatch }}>
      {children}
    </CommonContext.Provider>
  );
};
