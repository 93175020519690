import Detail from "@/components/Typography/Detail";
import Title from "@/components/Typography/Title";
import { Image } from "antd";

const MissionScreen = () => {
  return (
    <>
      <div className="container mx-auto pb-12">
        <div className="flex justify-end py-6">
          <Image src="images/logo.png" width={200} preview={false} />
        </div>
        <div className="text-center my-2">
          <h1 className="text-blue my-1 font-kanit font-normal text-[28px]">
            Mission, Vision and Value of Sekisui S-Lec (Thailand) Co.,Ltd.
          </h1>
          <h2 className="font-normal text-black my-1 font-supermarket text-[24px]">
            พันธกิจ วิสัยทัศน์ และคุณค่า ของ บริษัท เซกิซุย เอส-เล็ค (ประเทศไทย)
            จำกัด
          </h2>
        </div>
        <div className="my-6 block">
          <Title title={`พันธกิจ`} />
          <Detail
            desc={`เพื่อปกป้องชีวิตผู้คนด้วย interlayer film ที่สะดวกสบายและเป็นมิตรกับสิ่งแวดล้อม`}
          />
        </div>
        <div className="my-6 block">
          <Title title={`วิสัยทัศน์`} />
          <Detail
            desc={`เป็นผู้ให้บริการเต็มรูปแบบด้วยเทคโนโลยีขั้นสูงโดยการใช้กระบวนการอัจฉริยะและการพัฒนาด้านนวัตกรรม`}
          />
        </div>
        <div className="my-6 block">
          <Title title={`คุณค่า`} />
          <div className="font-normal text-black font-supermarket text-[22px] indent-8">
            <ul className="list-none p-0 m-0 leading-8">
              <li>- การบริการ</li>
              <li>- ความเร็ว</li>
              <li>- ความเป็นเลิศ</li>
              <li>- ความซื่อสัตย์</li>
              <li>- ความเป็นเจ้าของ</li>
              <li>- การปฏิบัติตามกฏหมาย</li>
              <li>- ความสัมพันธ์ที่ดี</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionScreen;
